<template>
  <div class="image">
    <img v-if="SVG" :data-src="image.url" :alt="image.alt ? image.alt : ''" />
    <picture
      v-else-if="image.url"
      ref="picture"
      class="image--simple"
      :style="style"
    >
      <source :srcset="imageSrcSet" :sizes="sizes" />
      <img
        ref="img"
        :alt="image.alt ? image.alt : ''"
        :importance="importance"
      />
    </picture>
  </div>
</template>

<script>
import { imageSrcSet, bunnyImageSrcSet } from '@/utils/imageSize'

export default {
  name: 'SimpleImage',
  props: {
    image: {
      type: Object,
      default: () => ({
        url: '',
        alt: '',
        dimentions: {
          width: 1,
          height: 1,
        },
      }),
    },
    imageCdn: {
      type: String,
      default: null,
    },
    sizes: {
      type: String,
      default: '100vw',
    },
    widths: {
      type: Array,
      default: () => [],
    },
    importance: {
      type: String,
      default: '',
    },
    provider: {
      type: String,
      default: 'prismic',
    },
  },
  data() {
    return {
      ready: false,
    }
  },
  computed: {
    SVG() {
      if (this.image.url) {
        const img = new URL(this.image.url)

        if (img.href.includes('.svg')) {
          return true
        }
      }
      return false
    },
    imageSrcSet() {
      if (this.image.url) {
        if (this.SVG) {
          return this.image.url
        }

        if (this.provider === 'sanity') {
          return bunnyImageSrcSet(this.image.url, this.image.dimensions)
        }

        return imageSrcSet(this.image, this.widths, this.imageCdn)
      }

      return ''
    },
    aspectRatio() {
      if (!this.image.dimensions.width || !this.image.dimensions.height)
        return 100
      return (this.image.dimensions.height / this.image.dimensions.width) * 100
    },
    style() {
      return this.SVG ? '' : `padding-top: ${this.aspectRatio}%;`
    },
  },
}
</script>

<style lang="scss">
.image--simple {
  width: 100%;
  height: 0;
  position: relative;

  & > img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
</style>
